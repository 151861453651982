import React, { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { TextField, InputAdornment } from "@mui/material";
import { isMobile } from "react-device-detect";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fade } from "react-reveal";
import callApi from "../../services/callApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loader from "../../components/Loader";
import "./DemoKine.css";
import { Helmet } from "react-helmet";

import startup from "../../assets/newiconos/zap.png";
import celu1 from "../../assets/kinesiologos/imagen_celular.jpg";
import celu2 from "../../assets/kinesiologos/imagen_2.png";
import celu3 from "../../assets/kinesiologos/imagen_3a.png";
import celu4 from "../../assets/kinesiologos/imagen_4a.png";
import celu5 from "../../assets/kinesiologos/imagen_5a.png";
import celu6 from "../../assets/kinesiologos/imagen_6a.png";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

export default function DemoKine() {
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = React.useState(false);
  const isDesktop = !isMobile;
  const [cellNumber, setCellNumber] = useState("");

  const pageTitle = `Teradomus: Potencia tus servicios de kinesiología`;
  const pageDescription = `Teradomus: Revoluciona tus servicios de kinesiología con agendamiento fácil, pagos automáticos y inteligencia artificial. Profesionaliza y digitaliza tu consulta hoy.`;

  const handleButtonClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const result = await callApi("/interesados-kine", "post", { cellNumber });

      setIsLoading(false);

      if (result?.statusCode === 200) {
        MySwal.fire("Nos contactaremos contigo", "", "success");
      } else {
        MySwal.fire("Error", "", "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
      MySwal.fire(
        "Algo falló al intentar validar. Por favor, intenta nuevamente.",
        "",
        "error"
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:url"
          content="https://www.teradomus.com/profesiones/kinesiologos"
        />
        <meta property="og:type" content="article" />
        <link
          rel="canonical"
          href="https://www.teradomus.com/profesiones/kinesiologos"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Article",
            headline: pageTitle,
            description: pageDescription,
            url: "https://www.teradomus.com/profesiones/kinesiologos",
          })}
        </script>
      </Helmet>
      <Loader isLoading={isLoading} />
      <Container>
        <Box
          sx={{
            mt: { xs: 3, lg: 3 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            bgcolor: "#fbfafe",
            borderRadius: 5,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              mb: 1,
              mt: { xs: 4, lg: 4 },
              fontSize: { lg: "34px", md: "16px", xs: "24px" },
              fontFamily: "Quicksand-Bold",
            }}
          >
            Potencia tus servicios de{" "}
            <span className="rainbow-text">kinesiología</span>
          </Typography>
          <Typography
            variant="h3"
            sx={{
              mt: 1,
              fontSize: { lg: "18px", md: "16px", xs: "16px" },
              fontFamily: "Quicksand-Regular",
              color: "#7a7a7a",
            }}
          >
            Dedícate a tus pacientes, <b>nosotros nos encargamos del resto</b>
          </Typography>
          <Box
            sx={{
              padding: "3.5rem",
              textAlign: "center",
              width: isDesktop ? "40%" : "95%",
            }}
          >
            <TextField
              type="tel"
              value={cellNumber}
              onChange={(e) => setCellNumber(e.target.value)}
              placeholder="Ingresa tu WhatsApp"
              variant="outlined"
              fullWidth
              inputProps={{
                maxLength: 14,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={handleButtonClick}
                      sx={{
                        bgcolor: "black",
                        textTransform: "none",
                        fontSize: { lg: "18px", md: "16px", xs: "14px" },
                        borderRadius: 8,
                        color: "white",
                        mr: isDesktop ? 1 : 0.5,
                        "&:hover": {
                          bgcolor: "black",
                        },
                      }}
                    >
                      ✨
                      <span
                        style={{
                          fontSize: isDesktop ? "15px" : "12px",
                          paddingRight: 4,
                          fontFamily: "Quicksand-Bold",
                        }}
                      >
                        Quiero saber más
                      </span>
                    </Button>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  paddingRight: "0 !important",
                  borderRadius: "10px",
                  fontSize: { lg: "18px", md: "16px", xs: "14px" },
                  "&.Mui-focused fieldset": {
                    borderColor: "black",
                  },
                },
              }}
            />
          </Box>
          <Typography
            variant="h3"
            sx={{
              mb: -1,
              fontSize: { lg: "16px", md: "15px", xs: "14px" },
              fontFamily: "Quicksand-Regular",
            }}
          >
            Apoyados por
          </Typography>
          <img
            src={startup}
            style={{
              height: isDesktop ? "75px" : "60px",
            }}
            alt="Startup Chile"
          />
        </Box>
        <Fade bottom duration={1000} delay={500}>
          <Box
            sx={{
              margin: "auto",
              bgcolor: "#fbfafe",
              mt: 10,
              mb: 10,
              borderRadius: 5,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                mb: 1,
                mt: 1,
                p: 3,
                fontSize: { lg: "32px", md: "25px", xs: "23px" },
                fontFamily: "Quicksand-Bold",
              }}
            >
              Aumenta tus ingresos
            </Typography>
            {isMobile ? (
              <Slider {...sliderSettings}>
                <Box>
                  <Grid item xs={12} lg={6}>
                    <Box
                      sx={{
                        borderRadius: 4,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          mt: 1,
                          fontSize: { lg: "20px", md: "16px", xs: "16px" },
                          fontFamily: "Quicksand-Bold",
                          height: "40px",
                          mb: 1,
                        }}
                      >
                        Captamos nuevos pacientes para ti
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: 4,
                          p: { xs: 1, lg: 3 },
                        }}
                      >
                        <Box
                          sx={{
                            width: "110px",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={celu1}
                            style={{
                              width: "110px",
                              borderRadius: "20px 20px 0 0",
                            }}
                            alt="info celular"
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundImage:
                                "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="h2"
                            sx={{
                              p: 2,
                              fontSize: { lg: "20px", md: "16px", xs: "16px" },
                              fontFamily: "Quicksand-Regular",
                            }}
                          >
                            Conectamos a nuestros pacientes con tus servicios,
                            generando nuevos ingresos para ti.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
                <Box>
                  <Grid item xs={12} lg={6}>
                    <Box
                      sx={{
                        borderRadius: 4,
                        mt: 1,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          fontSize: { lg: "20px", md: "16px", xs: "16px" },
                          fontFamily: "Quicksand-Bold",
                          height: "40px",
                          overflow: "hidden",
                          mb: 1,
                        }}
                      >
                        Capta tus propios pacientes con tu agenda online
                        personal
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: 4,
                          p: { xs: 1, lg: 3 },
                        }}
                      >
                        <Box
                          sx={{
                            width: "110px",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={celu2}
                            style={{
                              width: "110px",
                              borderRadius: "20px 20px 0 0",
                            }}
                            alt="info celular"
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundImage:
                                "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="h2"
                            sx={{
                              p: 2,
                              fontSize: { lg: "20px", md: "16px", xs: "16px" },
                              fontFamily: "Quicksand-Regular",
                            }}
                          >
                            Tendrás tu propia web de citas, tu y tus pacientes
                            podrán agendar 24/7 en tu sitio personal.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Slider>
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <Box
                    sx={{
                      borderRadius: 4,
                      mt: 1,
                      p: { xs: 3, lg: 3 },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        mt: 1,
                        fontSize: { lg: "20px", md: "16px", xs: "16px" },
                        fontFamily: "Quicksand-Bold",
                        height: "40px",
                        mb: 1,
                      }}
                    >
                      Captamos nuevos pacientes para ti
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: 4,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100px",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={celu1}
                          style={{
                            width: "100px",
                            borderRadius: "20px 20px 0 0",
                          }}
                          alt="info celular"
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundImage:
                              "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h2"
                          sx={{
                            p: 3,
                            fontSize: { lg: "20px", md: "16px", xs: "16px" },
                            fontFamily: "Quicksand-Regular",
                          }}
                        >
                          Conectamos a nuestros pacientes con tus servicios,
                          generando nuevos ingresos para ti.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    sx={{
                      borderRadius: 4,
                      mt: 1,
                      p: { xs: 3, lg: 3 },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        mt: 1,
                        fontSize: { lg: "20px", md: "16px", xs: "16px" },
                        fontFamily: "Quicksand-Bold",
                        height: "40px",
                        mb: 1,
                      }}
                    >
                      Capta tus propios pacientes con tu agenda online personal
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: 4,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100px",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={celu2}
                          style={{
                            width: "100px",
                            borderRadius: "20px 20px 0 0",
                          }}
                          alt="info celular"
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundImage:
                              "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h2"
                          sx={{
                            p: 3,
                            fontSize: { lg: "20px", md: "16px", xs: "16px" },
                            fontFamily: "Quicksand-Regular",
                          }}
                        >
                          Tendrás tu propia web de citas, tu y tus pacientes
                          podrán agendar 24/7 en tu sitio personal.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Fade>
        <Fade bottom duration={1000} delay={500}>
          <Box
            sx={{
              margin: "auto",
              bgcolor: "#fbfafe",
              mt: 10,
              mb: 10,
              borderRadius: 5,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                mb: 1,
                mt: 1,
                p: 3,
                fontSize: { lg: "32px", md: "25px", xs: "23px" },
                fontFamily: "Quicksand-Bold",
              }}
            >
              Ahorra tiempo en lo administrativo
            </Typography>
            {isMobile ? (
              <Slider {...sliderSettings}>
                <Box>
                  <Grid item xs={12} lg={6}>
                    <Box
                      sx={{
                        borderRadius: 4,
                        mt: 1,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          mt: 1,
                          fontSize: { lg: "20px", md: "16px", xs: "16px" },
                          fontFamily: "Quicksand-Bold",
                          height: "40px",
                          mb: 1,
                        }}
                      >
                        Recibe y envía recordatorios automatizados
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderRadius: 4,
                          p: { xs: 1, lg: 3 },
                        }}
                      >
                        <Box
                          sx={{
                            width: "110px",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={celu3}
                            style={{
                              width: "110px",
                              borderRadius: "20px 20px 0 0",
                            }}
                            alt="info celular"
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundImage:
                                "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="h2"
                            sx={{
                              p: 2,
                              fontSize: { lg: "20px", md: "16px", xs: "16px" },
                              fontFamily: "Quicksand-Regular",
                            }}
                          >
                            Minimiza los olvidos y las cancelaciones con
                            recordatorios que se envían sin que muevas un dedo.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
                <Box>
                  <Grid item xs={12} lg={6}>
                    <Box
                      sx={{
                        borderRadius: 4,
                        mt: 1,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          mt: 1,
                          fontSize: { lg: "20px", md: "16px", xs: "16px" },
                          fontFamily: "Quicksand-Bold",
                          height: "40px",
                          mb: 1,
                        }}
                      >
                        Olvídate de cobrar y enviar boletas
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderRadius: 4,
                          p: { xs: 1, lg: 3 },
                        }}
                      >
                        <Box
                          sx={{
                            width: "110px",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={celu4}
                            style={{
                              width: "110px",
                              borderRadius: "20px 20px 0 0",
                            }}
                            alt="info celular"
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundImage:
                                "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="h2"
                            sx={{
                              p: 2,
                              fontSize: { lg: "20px", md: "16px", xs: "16px" },
                              fontFamily: "Quicksand-Regular",
                            }}
                          >
                            Simplifica tus cobros y facturación con nuestro
                            sistema automatizado y seguro.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Slider>
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <Box
                    sx={{
                      borderRadius: 4,
                      mt: 1,
                      p: { xs: 3, lg: 3 },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        mt: 1,
                        fontSize: { lg: "20px", md: "16px", xs: "16px" },
                        fontFamily: "Quicksand-Bold",
                        height: "40px",
                        mb: 1,
                      }}
                    >
                      Recibe y envía recordatorios automatizados
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: 4,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100px",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={celu3}
                          style={{
                            width: "100px",
                            borderRadius: "20px 20px 0 0",
                          }}
                          alt="info celular"
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundImage:
                              "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h2"
                          sx={{
                            p: 3,
                            fontSize: { lg: "20px", md: "16px", xs: "16px" },
                            fontFamily: "Quicksand-Regular",
                          }}
                        >
                          Minimiza los olvidos y las cancelaciones con
                          recordatorios que se envían sin que muevas un dedo.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    sx={{
                      borderRadius: 4,
                      mt: 1,
                      p: { xs: 3, lg: 3 },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        mt: 1,
                        fontSize: { lg: "20px", md: "16px", xs: "16px" },
                        fontFamily: "Quicksand-Bold",
                        height: "40px",
                        mb: 1,
                      }}
                    >
                      Olvídate de cobrar y enviar boletas
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: 4,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100px",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={celu4}
                          style={{
                            width: "100px",
                            borderRadius: "20px 20px 0 0",
                          }}
                          alt="info celular"
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundImage:
                              "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h2"
                          sx={{
                            p: 3,
                            fontSize: { lg: "20px", md: "16px", xs: "16px" },
                            fontFamily: "Quicksand-Regular",
                          }}
                        >
                          Simplifica tus cobros y facturación con nuestro
                          sistema automatizado y seguro.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Fade>
        <Fade bottom duration={1000} delay={500}>
          <Box
            sx={{
              margin: "auto",
              bgcolor: "#fbfafe",
              mt: 10,
              mb: 10,
              borderRadius: 5,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                mb: 1,
                mt: 1,
                p: 3,
                fontSize: { lg: "32px", md: "25px", xs: "23px" },
                fontFamily: "Quicksand-Bold",
              }}
            >
              Transforma tus atenciones con Inteligencia artificial
            </Typography>
            {isMobile ? (
              <Slider {...sliderSettings}>
                <Box>
                  <Grid item xs={12} lg={6}>
                    <Box
                      sx={{
                        borderRadius: 4,
                        mt: 1,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          mt: 1,
                          fontSize: { lg: "20px", md: "16px", xs: "16px" },
                          fontFamily: "Quicksand-Bold",
                          height: "40px",
                          mb: 1,
                        }}
                      >
                        Recibe propuesta terapéutica basada en evidencia
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderRadius: 4,
                          p: { xs: 1, lg: 3 },
                        }}
                      >
                        <Box
                          sx={{
                            width: "110px",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={celu5}
                            style={{
                              width: "110px",
                              borderRadius: "20px 20px 0 0",
                            }}
                            alt="info celular"
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundImage:
                                "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="h2"
                            sx={{
                              p: 2,
                              fontSize: { lg: "20px", md: "16px", xs: "16px" },
                              fontFamily: "Quicksand-Regular",
                            }}
                          >
                            Mantén tus tratamientos actualizados según los
                            últimos avances científicos. Mejora la planificación
                            terapéutica de tus pacientes con inteligencia
                            artificial
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
                <Box>
                  <Grid item xs={12} lg={6}>
                    <Box
                      sx={{
                        borderRadius: 4,
                        mt: 1,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          mt: 1,
                          fontSize: { lg: "20px", md: "16px", xs: "16px" },
                          fontFamily: "Quicksand-Bold",
                          height: "40px",
                          mb: 1,
                        }}
                      >
                        Te ayudamos a resolver las dudas de tus pacientes
                      </Typography>
                      <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "space-between",
                          borderRadius: 4,
                          p: { xs: 1, lg: 3 },
                        }}
                      >
                        <Box
                          sx={{
                            width: "110px",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={celu6}
                            style={{
                              width: "110px",
                              borderRadius: "20px 20px 0 0",
                            }}
                            alt="info celular"
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundImage:
                                "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h2"
                          sx={{
                            p: 2,
                            fontSize: { lg: "20px", md: "16px", xs: "16px" },
                            fontFamily: "Quicksand-Regular",
                          }}
                        >
                          Recibe propuesta para responder a las dudas de tus
                          pacientes y para los informes de finalización de
                          tratamiento
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Slider>
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <Box
                    sx={{
                      borderRadius: 4,
                      mt: 1,
                      p: { xs: 3, lg: 3 },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        mt: 1,
                        fontSize: { lg: "20px", md: "16px", xs: "16px" },
                        fontFamily: "Quicksand-Bold",
                        height: "40px",
                        mb: 1,
                      }}
                    >
                      Recibe propuesta terapéutica basada en evidencia
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: 4,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100px",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={celu5}
                          style={{
                            width: "100px",
                            borderRadius: "20px 20px 0 0",
                          }}
                          alt="info celular"
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundImage:
                              "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h2"
                          sx={{
                            p: 3,
                            fontSize: { lg: "20px", md: "16px", xs: "16px" },
                            fontFamily: "Quicksand-Regular",
                          }}
                        >
                          Mantén tus tratamientos actualizados según los últimos
                          avances científicos. Mejora la planificación
                          terapéutica de tus pacientes con inteligencia
                          artificial
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    sx={{
                      borderRadius: 4,
                      mt: 1,
                      p: { xs: 3, lg: 3 },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        mt: 1,
                        fontSize: { lg: "20px", md: "16px", xs: "16px" },
                        fontFamily: "Quicksand-Bold",
                        height: "40px",
                        mb: 1,
                      }}
                    >
                      Te ayudamos a resolver las dudas de tus pacientes
                    </Typography>
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: 4,
                        p: { xs: 3, lg: 3 },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100px",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={celu6}
                          style={{
                            width: "100px",
                            borderRadius: "20px 20px 0 0",
                          }}
                          alt="info celular"
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundImage:
                              "linear-gradient(to top, #fbfafe, rgba(251,250,254,0))",
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          p: 3,
                          fontSize: { lg: "20px", md: "16px", xs: "16px" },
                          fontFamily: "Quicksand-Regular",
                        }}
                      >
                        Recibe propuesta para responder a las dudas de tus
                        pacientes y para los informes de finalización de
                        tratamiento
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Fade>

        <Fade bottom duration={1000} delay={500}>
          <Box
            sx={{
              mt: { xs: 3, lg: 3 },
              mb: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              bgcolor: "#fbfafe",
              borderRadius: 5,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                mb: 1,
                mt: { xs: 4, lg: 4 },
                fontSize: { lg: "34px", md: "16px", xs: "24px" },
                fontFamily: "Quicksand-Bold",
              }}
            >
              Potencia tus servicios de{" "}
              <span className="rainbow-text">kinesiología</span>
            </Typography>
            <Typography
              variant="h3"
              sx={{
                mt: 1,
                fontSize: { lg: "18px", md: "16px", xs: "16px" },
                fontFamily: "Quicksand-Regular",
                color: "#7a7a7a",
              }}
            >
              Dedícate a tus pacientes, <b>nosotros nos encargamos del resto</b>
            </Typography>
            <Box
              sx={{
                padding: "3.5rem",
                textAlign: "center",
                width: isDesktop ? "40%" : "95%",
              }}
            >
              <TextField
                type="tel"
                value={cellNumber}
                onChange={(e) => setCellNumber(e.target.value)}
                placeholder="Ingresa tu WhatsApp"
                variant="outlined"
                fullWidth
                inputProps={{
                  maxLength: 14,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        onClick={handleButtonClick}
                        sx={{
                          bgcolor: "black",
                          textTransform: "none",
                          fontSize: { lg: "18px", md: "16px", xs: "14px" },
                          borderRadius: 8,
                          color: "white",
                          mr: isDesktop ? 1 : 0.5,
                          "&:hover": {
                            bgcolor: "black",
                          },
                        }}
                      >
                        ✨
                        <span
                          style={{
                            fontSize: isDesktop ? "15px" : "12px",
                            paddingRight: 4,
                            fontFamily: "Quicksand-Bold",
                          }}
                        >
                          Quiero saber más
                        </span>
                      </Button>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    paddingRight: "0 !important",
                    borderRadius: "10px",
                    fontSize: { lg: "18px", md: "16px", xs: "14px" },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
            </Box>
            <Typography
              variant="h3"
              sx={{
                mb: -1,
                fontSize: { lg: "16px", md: "15px", xs: "14px" },
                fontFamily: "Quicksand-Regular",
              }}
            >
              Apoyados por
            </Typography>
            <img
              src={startup}
              style={{
                height: isDesktop ? "75px" : "60px",
              }}
              alt="Startup Chile"
            />
          </Box>
        </Fade>
      </Container>
    </>
  );
}
