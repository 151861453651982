import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Celu from "../../assets/newiconos/celu.png";
import Was from "../../assets/newiconos/was.png";
import { useMediaQuery } from 'react-responsive';
import { Link } from "@material-ui/core";

const TelWhat = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <Grid container>
      <Grid item md={2} lg={4}></Grid>
      <Grid item xs={12} md={8} lg={6}>
        <Box
          sx={{
            backgroundColor: "#eff0f1",
            borderRadius: '40px 0 0 40px',
            textAlign: 'center',
            ml: 2,
            p: {
              xs: '1px',
              md: '1px',
            },
          }}
        >
          <Typography sx={{ mt: 1, mb: { md: -2, xs: -0.1 }, fontFamily: 'Quicksand-Bold', fontSize: { xs: "13px", md: '20px' } }}>
            ¿Necesitas ayuda para agendar o tienes dudas?
          </Typography>
          <Typography sx={{ mb: { md: -1, xs: -0.1 }, fontFamily: 'Quicksand-light', textAlign: 'center', fontSize: { xs: "13px", md: '20px' } }}>
            <Link href="tel:56996906773" underline="none" color="inherit">
              <img
                src={Celu}
                alt="Llámanos"
                style={{
                  width: isMobile ? '30px' : '75px',
                  height: isMobile ? '30px' : '75px',
                  verticalAlign: 'middle',
                }}
              />
              Llámanos por teléfono ó
            </Link>
            <Link href="https://api.whatsapp.com/send/?phone=%2B56996906773&text=Hola%20amigos%20de%20Teradomus!%20Estoy%20interesado%20en%20tomar%20un%20tratamiento%20de%20kinesiolog%C3%ADa%20con%20ustedes.%20%C2%BFPodr%C3%ADan%20ayudarme%20a%20agendar%20una%20cita%3F&type=phone_number&app_absent=0" underline="none" color="inherit">
              <img
                src={Was}
                alt="WhatsApp"
                style={{
                  width: isMobile ? '30px' : '75px',
                  height: isMobile ? '30px' : '75px',
                  verticalAlign: 'middle',
                }}
              />
              Escríbenos al WhatsApp
            </Link>
          </Typography>
        </Box>
      </Grid>
      <Grid item md={2} lg={2} sx={{ backgroundColor: "#eff0f1" }}></Grid>
    </Grid>
  );
};

export default TelWhat;
