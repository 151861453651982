import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Link } from "@material-ui/core";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import logo from "../../assets/newiconos/Teradomus-gris.png";


export default function MenuSinServicios() {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const handleShowOptions = () => {
    if (isDesktop) {
      setOptionsOpen(true);
    }
  };

  const handleHideOptions = () => {
    if (isDesktop) {
      setOptionsOpen(false);

    }
  };

  const handleToggleOptions = () => {
    if (!isDesktop) {
      setOptionsOpen(prevState => !prevState);
    }
  };

  useEffect(() => {
    const handleCloseOptions = () => {
      setOptionsOpen(false);
    };

    // Detectar eventos de desplazamiento en el documento
    window.addEventListener('scroll', handleCloseOptions);

    return () => {
      window.removeEventListener('scroll', handleCloseOptions);
    };
  }, []);

  return (
    <>
      <Container>
        <Grid container>
          <Grid item lg={2} md={2} xs={5}>
            <Box sx={{ mt: 1.3, mb: 1.3, }}>
              <Link href="/" underline="none"
                color="inherit">
                <img
                  src={logo}
                  alt="Logo teradomus"
                  style={{
                    height: isDesktop ? '70px' : '50px',
                  }}
                />
              </Link>
            </Box>
          </Grid>
          <Grid item lg={9} md={7} xs={4}>
          </Grid>
          <Grid item lg={1} md={1} xs={2.5}>
            <Typography sx={{
              textAlign: 'right', mt: 3, fontFamily: 'Quicksand-Bold', fontSize: { lg: "24px", md: "24px", xs: "18px" }, color: '#aeaeae'
            }}>
              <Link
                href="/blog"
                underline="none"
                color="inherit"
              >
                Blog
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}