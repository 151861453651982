import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Link } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import logo from "../../assets/newiconos/Teradomus-gris.png";

export default function Form1() {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const handleShowOptions = () => {
    if (isDesktop) {
      setOptionsOpen(true);
    }
  };

  const handleHideOptions = () => {
    if (isDesktop) {
      setOptionsOpen(false);
    }
  };

  const handleToggleOptions = () => {
    if (!isDesktop) {
      setOptionsOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const handleCloseOptions = () => {
      setOptionsOpen(false);
    };

    // Detectar eventos de desplazamiento en el documento
    window.addEventListener("scroll", handleCloseOptions);

    return () => {
      window.removeEventListener("scroll", handleCloseOptions);
    };
  }, []);

  return (
    <>
      <Container>
        <Grid container>
          <Grid item lg={2} md={2} xs={5}>
            <Box sx={{ mt: 1.3, mb: 1.3 }}>
              <Link href="/" underline="none" color="inherit">
                <img
                  src={logo}
                  alt="Logo teradomus"
                  style={{
                    height: isDesktop ? "70px" : "50px",
                  }}
                />
              </Link>
            </Box>
          </Grid>
          <Grid item lg={8} md={6.5} xs={2}>
            {isDesktop && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  my: 4,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Regular",
                    fontSize: "14px",
                    color: "#aeaeae",
                  }}
                >
                  Horario de atención:{" "}
                  <span style={{ fontFamily: "Quicksand-Bold" }}>
                    lunes a domingo 8:00 - 20:00hrs
                  </span>
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item lg={1} md={1.7} xs={2.5}>
            <Typography
              sx={{
                textAlign: "center",
                mt: 3,
                fontFamily: "Quicksand-Bold",
                fontSize: { lg: "24px", md: "24px", xs: "18px" },
                color: "#aeaeae",
                cursor: "pointer",
                "&:hover": { color: "#aeaeae" },
              }}
              onClick={handleToggleOptions}
              onMouseEnter={handleShowOptions}
              onMouseLeave={handleHideOptions}
            >
              Servicios
            </Typography>
            {optionsOpen && (
              <Box
                sx={{
                  position: "fixed",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: isDesktop ? "20px" : "10px",
                  bgcolor: {
                    xs: "rgba(255, 255, 255, 0.9)",
                    md: "rgba(255, 255, 255, 0.6)",
                  },
                  borderRadius: "13px",
                  width: { xs: "130px", md: "180px", lg: "210px" },
                }}
                onMouseEnter={handleShowOptions}
                onMouseLeave={handleHideOptions}
              >
                <Box
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    margin: "auto",
                    p: 0.5,
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      mt: 1,
                      fontSize: { lg: "20px", md: "20px", xs: "15px" },
                      fontFamily: "Quicksand-Regular",
                      "&:hover": {
                        fontFamily: "Quicksand-Bold",
                        color: "#2669A8",
                      },
                    }}
                  >
                    <Link
                      href="/kinesiologia-a-domicilio"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Para Pacientes
                    </Link>
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      mt: 2,
                      mb: 2,
                      fontSize: { lg: "20px", md: "20px", xs: "15px" },
                      fontFamily: "Quicksand-Regular",
                      "&:hover": {
                        fontFamily: "Quicksand-Bold",
                        color: "#2669A8",
                      },
                    }}
                  >
                    <Link
                      href="/profesiones/kinesiologos"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Para Profesionales
                    </Link>
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      mt: 2,
                      mb: 2,
                      fontSize: { lg: "20px", md: "20px", xs: "15px" },
                      fontFamily: "Quicksand-Regular",
                      "&:hover": {
                        fontFamily: "Quicksand-Bold",
                        color: "#2669A8",
                      },
                    }}
                  >
                    <Link
                      href="https://saludtech.cl/widget/accounts/441/professions "
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Atención Médica{" "}
                    </Link>
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item lg={1} md={1.7} xs={2.5}>
            <Link href="/blog" underline="none" color="inherit">
              <Typography
                sx={{
                  textAlign: "right",
                  mt: 3,
                  fontFamily: "Quicksand-Bold",
                  fontSize: { lg: "24px", md: "24px", xs: "18px" },
                  color: "#aeaeae",
                }}
              >
                Blog
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
