import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import calen from "../../../assets/newiconos/calen.png";
import cora from "../../../assets/newiconos/cora.png";
import mejoresprecios from "../../../assets/newiconos/mejoresprecios.png";
import casa from '../../../assets/newiconos/casa.png';
import Grid from "@mui/material/Grid";

function Atencion() {
  const data = [
    {
      image: calen,
      title: 'Lunes a Domingo',
      text: '8:00hrs a 20:00hrs',
    },
    {
      image: cora,
      title: 'ISAPRE',
      text: 'Reembolsable en todas las ISAPRES',
    },
    {
      image: mejoresprecios,
      title: 'Desde $20.000',
      text: 'por sesión',
    },
    {
      image: casa,
      title: 'Provincia de Santiago',
      text: 'Valparaíso, Concepción, Antofagasta y más',
    },
  ];

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item md={12} xs={12} sx={{ display: "flex",justifyContent:"center" }}>
        {data.map((item, index) => (
          <Box
            key={index}
            sx={{
              width: '200px',
              textAlign: 'center',
              borderRadius: '13px',
              boxShadow: '8px 8px 8px 8px rgba(0, 0, 0, 0.14)',
              margin: '10px', padding: "8px"
            }}
          >
            <img src={item.image} alt={item.title} style={{ width: '65px', height: '65px' }} />
            <Typography sx={{ fontFamily: 'Quicksand-Bold', color: '#1e7aa8', lineHeight: 1.1 }}>
              <b>{item.title}</b>
            </Typography>
            <Typography sx={{ fontFamily: 'Quicksand-Light', color: '#1e7aa8', lineHeight: 1.1 }}>
              {item.text}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}

export default Atencion;
