import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Container, Typography } from "@material-ui/core";
import calen from "../../assets/newiconos/calen.png";
import cora from "../../assets/newiconos/cora.png";
import mejoresprecios from "../../assets/newiconos/mejoresprecios.png";
import casa from "../../assets/newiconos/casa.png";

const LOCAL_IMAGES = [calen, cora, mejoresprecios, casa];

const SLIDE_SETTINGS = {
  dots: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const CarouselMove = () => {
  const [images, setImages] = useState([...LOCAL_IMAGES]);

  const handleAfterChange = (slideIndex) => {
    if (slideIndex === images.length - 3) {
      setImages((prevImages) => [...prevImages, ...LOCAL_IMAGES]);
    }
  };

  const renderImage = (image, index, altText, title, description) => (
    <Container key={index}>
      <Box
        sx={{
          width: "auto",
          height: "145px",
          textAlign: "center",
          bgcolor: "white",
          borderRadius: "13px",
          boxShadow: "8px 8px 8px 8px rgba(0, 0, 0, 0.16)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 4,
          mt: 5,
        }}
      >
        <img
          src={image}
          alt={altText}
          style={{ width: "55px", height: "55px", marginTop: "3px" }}
        />
        <Typography
          style={{
            fontSize: "15px",
            fontFamily: "Quicksand-Bold",
            color: "#1e7aa8",
            lineHeight: 1.2,
          }}
        >
          <b>{title}</b>
        </Typography>
        <Typography
          style={{
            fontSize: "15px",
            fontFamily: "Quicksand-Light",
            color: "#1e7aa8",
            lineHeight: 1.1,
          }}
        >
          {description}
        </Typography>
      </Box>
    </Container>
  );

  const renderImages = () => {
    return images.map((image, index) => {
      if (index === 0) {
        return renderImage(
          calen,
          index,
          "Todos los dias",
          "Lunes a Domingo",
          "8:00hrs a 20:00hrs"
        );
      } else if (index === 2) {
        return renderImage(
          cora,
          index,
          "Salud",
          "ISAPRE",
          "Reembolsable en todas las ISAPRES"
        );
      } else if (index === 3) {
        return renderImage(
          mejoresprecios,
          index,
          "Valor sesión",
          "Desde $23.000",
          "por sesión"
        );
      } else if (index === 4) {
        return renderImage(
          casa,
          index,
          "Comunas de Santiago y regiones",
          "En todo Chile",
          "podemos llegar al 80% de los hogares chilenos"
        );
      }
    });
  };

  return (
    <Box sx={{ mb: 4, mt: 1 }}>
      <Slider {...SLIDE_SETTINGS} afterChange={handleAfterChange}>
        {renderImages()}
      </Slider>
    </Box>
  );
};

export default CarouselMove;
